<template>
  <div class="committee">
    <h3 class="sub-title">ASCVTS Officer and Councilors</h3>
    <!-- <div class="list-group">
      <div class="list-group-item">
        <div class="list-group-content list-title">
          <div class="t-lt">Position</div>
          <div class="t-mid">Name</div>
          <div class="t-rt">Area</div>
        </div>
      </div>
      <div class="list-group-item" v-for="(item, index) in officers" :key="index">
        <div class="list-group-content">
          <div class="left">
            <span>{{item.position}}</span>
          </div>
          <div class="mid">{{item.name}}</div>
          <div class="right">{{item.area}}</div>
        </div>
      </div>
    </div> -->
    <a-row class="guest-list" :gutter="[{ xs: 10, sm: 20, md: 10, lg: 10 }, 30]">
      <div class="desc dK:text-right font-medium dk:text-base mb-2.5">
        * ASCVTS Officer and Councilors listed in Alphabetical order of their name.
      </div>
      <a-col :sm="24" v-for="(item, index) in officers" :key="index" style="overflow: hidden;">
        <div class="guest-item p-2.5 shadow-lg">
          <div class="flex" style="align-items: center;">
            <!-- <div style="display: flex;justify-content: center;text-align: center;">
            </div> -->
            <img class="avatar mr-3.5" :src="item.avatar" />
            <div>
              <div class="guest-name mb-1.5 text-2xl" >{{ item.realname }}</div>
              <!-- <div class=" mb:mb-2" style="font-style: italic;">{{ item.area }}</div> -->
<!--              <div class="mb:mb-2" style="font-style: italic;"><span>{{ item.position }}</span></div>-->
              <div class="mb:mb-2" style="font-style: italic;">{{ item.site }}</div>
              <!-- <div class="role">
                <span class="btn" v-for="(role,i) in item.speakerRoleList" :key="i">
                  {{ role.titleEn }}
                </span>
              </div> -->
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {getExpertsByGroupId} from "@/api/agenda";

export default {
  name: "Officer",
  data() {
    return {
      officers: [
        /*{
          position: "Society President",
          name: "Sanghoon Jheon",
          area: "Seoul",
          site:"Seoul National University College of Medicine,Republic of Korea",
          avatar: "https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/01/31/57be27f87c844d42a33123eb185d4167.jpg"
        },
        {
          position: "Secretary General, Scientific committee chair, Adult cardiac domain chair",
          name: "Minoru Ono",
          area: "Tokyo",
          site:"The University of Tokyo, Department of Cardiac Surgery, Japan",
          avatar: "https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/01/31/2d5eb065fa4243bea984fc31653153d7.jpg"
        },
        {
          position: "Treasurer, HP committee chair",
          name: "Jun Nakajima",
          area: "Tokyo",
          site:"University of Tokyo Graduate School of Medicine, Japan",
          avatar: "https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/01/31/42523d1a8ecd4e1092e4d7e8f4d142db.jpg"
        },
        {
          position: "Assistant SG in Seoul, 2023 Congress President, Aortic domain chair",
          name: "Kay-Hyun Park",
          area: "Seoul",
          site:"Seoul National University Bundang Hospital, Republic of Korea",
          avatar: "https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/01/31/ff8ff0e4f61544329b78ad0e98c77968.jpg"
        },
        {
          position: "Councilor, 2024 Congress President",
          name: "Nianguo Dong",
          area: "Wuhan",
          site:"Wuhan Union Hospital,China",
          avatar: "https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2023/09/18/0cc850a07b7c463d8656c00fed83733c.jpg"
        },*/
      ]
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      getExpertsByGroupId({ groupId: '1405773366272256'}).then((res) => {
        if (res.success) {
          if (res.data) {
            this.guestListSortByLastName(res.data || [])
          }
        }
      })
    },
    guestListSortByLastName(list) {
      list.sort(function(a, b) {
        var wordsA = a.realname.trim().split(" "); // 将a的realname属性按空格分割成单词数组
        var wordsB = b.realname.trim().split(" "); // 将b的realname属性按空格分割成单词数组
        var lastWordA = wordsA[wordsA.length - 1].toLowerCase(); // 获取a的最后一个单词，并转换为小写
        var lastWordB = wordsB[wordsB.length - 1].toLowerCase(); // 获取b的最后一个单词，并转换为小写
        return lastWordA.localeCompare(lastWordB); // 使用localeCompare方法比较字符串，返回排序结果
      });
      let guestList = list;
      this.officers = guestList
    },
  }
}
</script>

<style lang="less" scoped>
.committee {
  .sub-title {
    font-weight: 900;
    font-size: 27px;
    line-height: normal;
    margin: 30px 0px 20px 0px;
    padding-bottom: 10px;
    border-bottom: solid 1px #ddd;
  }
  .list-group {
    .list-group-item {
      border: 1px solid rgba(0,0,0,.125);
      border-bottom: none;
      padding: 12px 20px;
      line-height: 26px;
      font-size: 16px;
      .list-group-content {
        display: flex;
        align-items: center;
        .t-lt {
          flex: 7;
        }
        .t-mid {
          flex: 2;
        }
        .t-rt {
          flex: 3;
        }
        .left {
          flex: 7;
          span {
            position: relative;
            &::before {
              content: "";
              position: absolute;
              left: 0;
              bottom: -3px;
              background: linear-gradient(to right, #19317e, #eaeff8);
              height: 3px;
              width: 100%;
            }
          }
        }
        .mid {
          flex: 2;
        }
        .right {
          flex: 3;
          font-family: "Akrobat Regular";
        }
        @media (max-width:992px) {
          display: block;
          &.list-title {
            div {
              display: none;
            }
          }
        }
      }
      &:first-child {
        border-radius: 4px 4px 0 0;
      }
      &:last-child {
        border-radius: 0 0 4px 4px;
        border-bottom: 1px solid rgba(0,0,0,.125);
      }
      &:nth-of-type(2n + 1) {
        background-color: #fafafa;
      }
    }
  }
}
.guest-list {
  .desc {
    color: #dc3545!important;
  }
      margin: 0!important;
    }
    .guest-item {
      &:hover {
        background-color: rgba(254, 242, 203, 0.5);
      }
      cursor: pointer;
      position: relative;
      border: solid 1px #ccc;
      border-radius: 5px;
      /deep/ .highlight {
        background-color: #FFFF88;
      }
      .download {
        display: flex;
        align-items: center;
        background: linear-gradient(90deg, #24A7F2 0%, #1B3288 100%);
        font-weight: 500;
        border-top-right-radius: 5px;
        position: absolute;
        right: 0;
        top: 0;
        padding: 2px 10px;
        color: #ffffff;
        font-size: 18px;
        font-family: "Roboto Condensed", sans-serif;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        .icon {
          font-size: 15px;
          color: #ffffff;
          margin-right: 2px;
        }
      }
      .office {
        font-size: 15px;
        line-height: 130%;
        font-style: italic;
        min-height: 40px;
        color: #444444;
      }
      .avatar {
        border-radius: 50%!important;
        width: 100px;
        height: 100px;
        border: 1px solid #dee2e6;
        padding: 0 2px;
      }
      .role {
        span {
          padding: 0.25em 0.4em;
          color: white;
          border-radius: 0.25rem;
        }
        span + span {
          margin-left: 0.25rem;
        }
      }
    }
</style>
